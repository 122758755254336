export default {
  error: {
    processing: {
      description:
        'We regret to inform you that we encountered some errors while processing this recording and could not proceed further',
      record: 'Record Again'
    }
  },
  delete: {
    title: 'Are you sure you want to delete this guide?',
    description:
      'This guide will be deleted forever and any links associated with this guide will not be available anymore.'
  },
  crop_frame: {
    save: 'Crop Image',
    update: 'Update Image',
    clear: 'Revert to Original',
    title: 'Crop Image',
    description:
      'Select a specific portion of the image and we will remove everything outside of it.',
    apply_desc: 'Apply this change to all clips?'
  },
}