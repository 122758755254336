export default {
  create_collection: 'Add to Collection',
  add_collection: 'Add to a collection',
  knowledge_hub_settings: 'Knowledge Base Settings',
  edit_collection: 'Update collection',
  collection_label: 'Name of the collection',
  collection_input_error: 'Enter a name for the collection',
  collection_select_error: 'Please select a collection',
  update_generic: 'Update',
  video_title: 'Video title',
  guide_title: 'Guide title',
  guide_input_error: 'Enter a name for guide',
  document_title: 'Document title',
  document_input_error: 'Enter a name for document',
  collection_desc: 'Description of the collection',
  collection_desc_error: 'Enter a description for the collection',

  video_desc_error: 'Enter a description for the video',

  collection_min_err: `Please select a video`,
  create_video: {
    title: 'Start creating a new video here.',
    desc: 'What are you creating?',
    back_to_main: 'Back to Menu',
    install_extension: 'Install Chrome Extension',
    not_installed: 'if you haven’t already.',
    watch_video: 'See how it’s done!',
    choose_file: 'Choose file',
    introducing: 'Introducing',
    zing_by_trainn:
      'Try out new editor to create and edit walkthroughs, pdf to videos, and more.',
    size_exceeded: "File size exceeded the limit. Max: %{size}MB",
    walkthrough: {
      title: 'Web App Walkthrough',
      title_long: 'Create web app walkthrough video',
      desc: "To create - Walkthroughs, How-to's for your web app",
      desc_long:
        'Record your web application’s screen, add transitions and annotations, add voiceovers and captions, music and publish.',
      video_title:
        'Create an app walkthrough video using Trainn’s Chrome Extension',
      step1: {
        title: 'Step One:',
        desc: 'Ensure you are on the correct URL to start your recording.'
      },
      step2: {
        title: 'Step Two:',
        desc:
          'Click the extension on your chrome browser, follow the steps and start recording.'
      }
    },
    instant: {
      title: 'Instant(Screen + Cam)',
      title_long: 'Create instant screen recording',
      desc: 'To record - Webinars, Product demos or any browser screen',
      desc_long: {
        1: 'No time to record elaborate videos? No problem!',
        2: 'Record your screen, add your face from the web cam. Instantly share this personalized video for a swift communication.'
      },
      video_title:
        'Create an Instant Screen+Cam video using Trainn Instant chrome extension',
      step1: {
        title: 'Step One:',
        desc:
          'Click the extension on your chrome browser, follow the steps to record your screen, your web cam or both.'
      },
      step2: {
        title: 'Step Two:',
        desc: 'Share this instant video with your audience for a quick win.'
      }
    },
    images: {
      title: 'Convert Images into Video',
      choose_file: 'Upload an image file',
      desc: 'To convert a sequence of images into a video',
      desc_long:
        'Upload an image or screenshots, add voice and music and convert to a video and share in seconds.',
      video_title: 'Convert images into videos, in a snap'
    },
    pdf: {
      title: 'Convert Presentations into Video',
      choose_file: 'Upload a presentation',
      desc: 'To convert boring presentations into engaging videos',
      desc_long: `Need to convert a pitch deck into a video? <p> Just upload your presentation, add voice and music and convert to a video in seconds.</p>`,
      video_title: 'Convert pitchdecks and presentations into videos, in a snap'
    },
    desktop_recording: {
      title: 'Desktop App Walkthrough',
      choose_file: 'Upload a desktop recording',
      title_long: 'Create walkthrough for desktop app',
      desc: "To create - Walkthroughs, How-to's for your desktop app",
      desc_long:
        'Upload a desktop recording, add voice and music and convert to a video and share in seconds.',
      video_title: 'Convert desktop recordings into videos, in a snap'
    },
    mobile_recording: {
      title: 'Mobile App Walkthrough',
      choose_file: 'Upload a mobile recording',
      title_long: 'Create walkthrough for mobile app',
      desc: "To create - Walkthroughs, How-to's for your mobile app",
      desc_long:
        'Upload a mobile recording, add voice and music and convert to a video and share in seconds.',
      video_title: 'Convert mobile recordings into videos, in a snap'
    },
    manual_upload: {
      already_having_video: 'Already having a video?',
      upload: 'Upload',
      label: 'Upload your existing video',
      title: 'Upload video',
      title_document: 'Upload document',
      lesson_title: 'Upload a Video lesson',
      description: 'Upload your existing training video.',
      step1:
        'Upload your existing video. The uploaded video cannot be edited later.',
      step2: 'Share video with your audience.'
    },
    document_upload: {
      label: 'Choose a pdf to upload',
    },
    description:
      'Just some more information first and we’ll start creating the video',
    upload_description:
      'Just some more information, before uploading the video',
    upload_video: 'Upload your mp4 video',
    upload_video_desc:
      'Video should be in mp4 format and size less than 20MB. Please contact us, if you wish to upload a video with size greater than 20MB.',
    input_label: 'Video title',
    input_lable_document: 'Document title',
    input_placeholder: 'Give your video a descriptive title',
    input_placeholder_document: 'Leave it blank to use the uploaded PDF name.',
    lesson_input_label: 'Lesson title',
    lesson_input_placeholder: 'Give your lesson a descriptive title',
    invalid_input: 'Please enter a name',
    invalid_size: 'You have reached your maximum file size limit',
    url_label: 'Record from URL',
    url_placeholder: 'https://yourwebsite.com',
    invalid_url: 'Please enter an url to proceed',
    invalid_url_format: 'Please enter a valid url',
    upload_pdf: 'Upload a PDF or an image',
    upload_any: 'Upload a PDF, video or an image',
    create: 'Start Creating',
    upload: 'Upload and Publish',
    note:
      'On clicking "Create" you will be redirected to the corresponding url where you can start your recording using the "Trainn" extension.'
  },
  collection: {
    new: 'Create new collection',
    existing: 'Add to existing collection',
    add_description: 'Add description for your collection',
    add_thumbnail_image: 'Add thumnail image',
    download_thumbnail:
      "You can download thumbnail images for free, from this opensource website <a href='https://www.manypixels.co/gallery' target='_blank'>Manypixels</a>.",
    delete: {
      title: 'Are you sure you want to delete this collection?',
      description:
        'On deleting, this collection will be deleted forever. All the links associated with this collection will not be available anymore.',
      delete_btn: 'Yes, Delete it!',
      cancel: "No, don't delete it"
    },
    description:
      'Trainn offers a feature where you can group a set of videos and share it with your customers via an unique URL.'
  },
  video: {
    add_description: 'Add description for your video',
    create_from_extension:
      'Create Trainn Walkthroughs or Instants directly by clicking on the respective plugins on your browser.'
  },
  browser_refresh: 'Kindly refresh the page if already installed!',
  guide:
    'Goto the corresponding url and click on the trainn extension in the upper right of your browser to get started.',
  insta_guide:
    'Goto the corresponding url and click on the trainn insta extension in the upper right of your browser to get started.',
  filters: {
    sort: 'Sort by',
    or: 'or',
    default: 'Filter videos in',
    drafts: 'drafts',
    live: 'live',
    walkthrough: 'walkthrough',
    instant: 'instant',
    pitch: 'pitch',
    all: 'view all'
  },
  folders: {
    edit_folder_name: 'Update Folder',
    folder_name: 'Name of the Folder',
    folder_input_error: 'Enter a name for the Folder',
    empty: 'No folders found'
  },
  select_video: {
    title: 'Import Video from Library',
    create_article: 'Add Article',
    create_presentation: 'Add PDF',
    sub_title: 'Your Library',
    no_result: 'No result found'
  },
  select_courses: {
    title: 'Import Trainn Course',
    sub_title: 'Your Library',
    no_result: 'No result found'
  },
  article_creation: {
    title: 'Create an Article',
    label: 'Article title',
    description: 'Create an article from scratch',
    input_placeholder: 'Give your article a descriptive title',
  },
  reorder_chapter: {
    title: 'Reorder chapters'
  },
  usageLimit: {
    softWarning: {
      text: `⚠️ You have used up %{count}/100 MAUs for the month. <a class="t-td--border" target="_blank" href="/settings/pricing"> Upgrade</a> for undisrupted usage.`
    },
    hardWarning: {
      text: `🛑 You have crossed your free usage limit of 100 MAUs for this month. <a class="t-td--border" target="_blank" href="/settings/pricing"> Upgrade!</a>`
    }
  },
  marketingText:
    "<b>Meet Zing by Trainn:</b> The easy, fast, and fun way to create product videos 😊 <a class='t-td--border' href='/record'>Try now ➝ </a>",
  trialEndingText:
    "Your trial will end in another %{relativeTime}. Please <a href='/settings/pricing'>subscribe</a> to one of our paid plans for continuous usage.",
  trialEnded:
    'Your trial has ended. Please subscribe to one of our paid plans for continuous usage.'
};
